import {useNavigate} from "react-router-dom";

function TopicLayout({ children }) {
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/menu');
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0,
            color: '#6b6d6e'
        }}>
            <img style={{width: '25%', marginTop: '3vh', marginBottom: '1vh'}} src={'/images/google-logo.svg'}/>

            <div style={{display:'flex', flexDirection:'column', width:'90vw'}}>
                {children}
            </div>

            <img style={{position: 'absolute', width: '30%', bottom: '7vh', right: '7vw'}} onClick={handleImageClick}
                 src={'/icons/indietro-icon.png'}/>

        </div>
    );
}

export default TopicLayout;