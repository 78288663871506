import TopicLayout from "../components/TopicLayout";
import TopicNavigation from "../components/TopicNavigation";
import {useNavigate} from "react-router-dom";


function Topic4() {
    const navigate = useNavigate()

    return (
        <TopicLayout>
            <h1 style={{
                fontWeight: '500',
                fontSize: '70px',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '1vh',
                marginBottom: 0
            }}>
                Consulenze <br/> dedicate
            </h1>
            <img src={'/icons/menu-icon-4.svg'} style={{width: '15%', margin: 'auto', paddingTop: '3vh'}}/>

            <p style={{
                fontWeight: 'normal',
                fontSize: '38px',
                marginTop: '2vh',
                marginBottom: 0,
                textAlign: 'center',
                paddingTop: '2vh'
            }}>
                Per supportare le aziende nel loro percorso <br/>
                di adozione dell’IA, all’interno dei nostri <span
                style={{fontWeight: '700', backgroundColor: '#e73b25', color: 'white'}} onClick={() => {navigate('/topic/2');}}>Spazi Interattivi</span> <br/>
                e online, offriamo la possibilità di confrontarsi <br/>
                con un esperto attraverso <span style={{fontWeight: '700'}}>consulenze dedicate</span> <br/>
                per comprendere meglio quali progetti di intelligenza <br/>
               artificiale possano rispondere alle proprie esigenze.<br/>
            </p>

            <p style={{
                fontWeight: '700',
                fontSize: '35px',
                textAlign: 'right',
                padding: '5vw',
                marginLeft: 'auto',
                marginTop: '2vh',
                textTransform: 'uppercase',
                paddingBottom: '0px'
            }}>
                Sei un’impresa? <br/>
                prenota una<br/>
                 consulenza <br/>
                <span style={{color: '#007cc2'}}>↘</span>

            </p>

            <img style={{width: '18%', paddingRight: '5vw', marginLeft: 'auto', paddingTop: '0px'}}
                 src={'/qrcodes/qr_new.png'}/>

            <img style={{width: '50%', position: 'absolute', bottom: '16.7vh', left: '5vw'}}
                 src={'/images/illustration-section-4.svg'}/>

            <TopicNavigation currentId={4}/>
        </TopicLayout>
    )
}

export default Topic4;