import TopicLayout from "../components/TopicLayout";
import TopicNavigation from "../components/TopicNavigation";


function Topic6() {
    return (
        <TopicLayout>
            <h1 style={{
                fontWeight: '500',
                fontSize: '70px',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: '1vh',
                marginBottom: 0
            }}>
                Progetti di <br/> innovazione
            </h1>
            <img src={'/icons/menu-icon-6.svg'} style={{width: '15%', margin: 'auto', paddingTop: '3vh'}}/>

            <p style={{
                fontWeight: 'normal',
                fontSize: '38px',
                marginTop: '2vh',
                marginBottom: 0,
                textAlign: 'center',
                paddingTop: '2vh'
            }}>
                Con il bando <span style={{fontWeight: '700'}}>crescerAI</span>, lanciato a ottobre 2023 <br/>
                da Fondo per la Repubblica Digitale con il supporto <br/>
                di Google.org e appena conclusosi, abbiamo <br/>
                messo a disposizione più di 2 milioni di euro per <br/>
                progetti rivolti allo sviluppo di soluzioni di intelligenza <br/>
                artificiale di tipo open source destinate <br/>
                alle piccole e medie imprese del Made in Italy.
            </p>

            <p style={{
                fontWeight: '700',
                fontSize: '35px',
                textAlign: 'right',
                padding: '5vw',
                marginLeft: 'auto',
                marginTop: '0.5vh',
                textTransform: 'uppercase',
                paddingBottom: '0px'
            }}>
                Scopri <br/> i progetti <br/>
                che  sono stati <br/>
                selezionati <br/>
                <span style={{color: '#007cc2'}}>↘</span>

            </p>

            <img style={{width: '18%', paddingRight: '5vw', marginLeft: 'auto', paddingTop: '0px'}}
                 src={'/qrcodes/qr-section-6.svg'}/>

            <img style={{width: '60%', position: 'absolute', bottom: '-3vh', left: '0vw'}}
                 src={'/images/illustration-section-6.png'}/>


            <TopicNavigation currentId={6}/>
        </TopicLayout>
    )
}

export default Topic6;